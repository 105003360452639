import React from 'react'
import { BrowserRouter, Route, Switch} from 'react-router-dom';
import Home from './components/home/Home'
import Registration from './components/users/Registration'
import Login from './components/users/Login'
import InnerLayout from './components/layout/InnerLayout'
import UserOrderHistory from './components/users/UserOrderHistory';
import UserAction from './components/users/user-actions/UserAction';
import MemberGroup from './components/masterRecord/memberGroup/MemberGroup';
import AddMemberGroup from './components/masterRecord/memberGroup/AddMemberGroup';
import AddMember from './components/masterRecord/member/AddMember';
import Member from './components/masterRecord/member/Member';
import MemberViewDetails from './components/masterRecord/member/MemberViewDetails';
import Scheme from './components/groupLoan/Scheme';
import AddScheme from './components/groupLoan/AddScheme';
import Dashboard from './components/dashboard/Dashboard';
import LoanApplication from './components/groupLoan/LoanApplication';
import LoanApproval from './components/groupLoan/LoanApproval';
import DisburseLoan from './components/groupLoan/DisburseLoan';
import LoanApprovalDetails from './components/groupLoan/LoanApprovalDetails';
import AllApplications from './components/groupLoan/AllApplications';
import EmiCalculator from './components/groupLoan/EmiCalculator';
import DueEmis from './components/emis/DueEmis';
import PaidDetailView from './components/dashboard/PaidDetailView';
import RecievedDetailView from './components/dashboard/RecievedDetailView';
import Profit from './components/profit/Profit';
import ProcessingFee from './components/processing-fee/ProcessingFee';
import AddProcessingFee from './components/processing-fee/AddProcessingFee';
import CloseAccount from './components/groupLoan/CloseAccount';
import OutstandingDetailView from './components/dashboard/OutstandingDetailView';
import Expense from './components/expenses/Expense';
import AddExpense from './components/expenses/AddExpense';
import EmiCardPrint from './components/print/EmiCardPrint';
import ListUser from './components/users/ListUser';
import AddUser from './components/users/AddUser';
import Village from './components/village/Village';
import AddVillage from './components/village/AddVillage';
import MemberVillageMap from './components/village/MemberVillageMap';
import EmiApproval from './components/emis/EmiApproval';
import UserMenus from './components/users/UserMenus';
import ROUTE from './constants/routes';
import EditScheme from './components/modification/EditScheme';
import EditMember from './components/modification/EditMember';
import EditMemberGroup from './components/modification/EditMemberGroup';
import EditExpense from './components/modification/EditExpense';
import EditLoanApplication from './components/modification/EditLoanApplication';
import EditVillage from './components/modification/EditVillage';
import EditProcessingFee from './components/modification/EditProcessingFee';
import PrintLoan from './components/groupLoan/PrintLoan';
import SanctionLatter from './components/print/SancationLatter';
import DownloadDoc from './components/print/DownloadDoc';
import OpenSavingAccount from './components/savingAccount/OpenSavingAccount';
import SavingAccountReport from './components/savingAccount/SavingAccountReport';
import SavingAccountDepositedReport from './components/savingAccount/SavingAccountDepositedReport';
import PassbookPdf from './components/savingAccount/PassbookPdf';
import AddTransaction from './components/savingAccount/AddTransaction';
import LoanCashAcTransfer from './components/groupLoan/LoanCashAcTransfer';
import collectionSheet from './components/emis/CollectionSheet';
import AdminInvestorDashboard from './components/dashboard/AdminInvestorDashboard';
import InvestorDashboard from './components/dashboard/InvestorDashboard';
import LandingPage from './components/dashboard/LandingPage';
import MemberApproval from './components/masterRecord/member/MemberApproval';
import AdminMainLedgerDetail from './components/dashboard/AdminMainLedgerDetail';
import ProfitLedgerDetails from './components/dashboard/ProfitLedgerDetails';
import MainLedgerDetailInvestor from './components/dashboard/MainLedgerDetailInvestor';
import CompanyExpenseSlipPdf from './components/print/CompanyExpenseSlipPdf';
import LoanActions from './components/groupLoan/LoanActions';
import OverdueEmis from './components/emis/OverdueEmis';
import OverdueNoticePrint from './components/print/OverdueNoticePrint';
import Cashbook from './components/reports/Cashbook';
import LoanDeleteApproval from './components/groupLoan/LoanDeleteApproval';
import PrintJointLiabilityForm from './components/print/PrintJointLiabilityForm';
import LastEmiDepositReport from './components/reports/LastEmiDepositReport';
import { useEffect } from 'react';
import LoanTopup from './components/others/LoanTopup';
import DatabaseBackup from './components/others/DatabaseBackup';
import AllClosedLoanApplications from './components/groupLoan/AllClosedLoanApplications';
function Routes(props) {
    useEffect(()=>{
        console.log("change event", props);
    },[])
    return (
        <BrowserRouter>
            <Switch>
                <InnerLayout exact path={ROUTE.LANDINGPAGE} component={LandingPage} />
                <InnerLayout exact path={ROUTE.DASHBOARD} component={Dashboard} />
                <InnerLayout exact path={ROUTE.DASHBOARD_INVESTOR} component={InvestorDashboard} />
                <InnerLayout exact path={ROUTE.MEMBER_GROUP} component={MemberGroup} />
                <InnerLayout exact path={ROUTE.ADD_MEMBER_GROUP} component={AddMemberGroup} />
                <InnerLayout exact path={ROUTE.ADD_MEMBER} component={AddMember} />
                <InnerLayout exact path={ROUTE.SCHEME} component={Scheme} />
                <InnerLayout exact path={ROUTE.VIEW_MEMBER_DETAIL} component={MemberViewDetails} />
                <InnerLayout exact path={ROUTE.ADD_SCHEME} component={AddScheme} />
                <InnerLayout exact path={ROUTE.LOANAPPLICATION} component={LoanApplication} />
                <InnerLayout exact path={ROUTE.LOANAPPROVAL} component={LoanApproval} />
                <InnerLayout exact path={ROUTE.DISBURSE_LOAN} component={DisburseLoan} />
                <InnerLayout exact path={ROUTE.LOAN_APPROVAL_DETAILS} component={LoanApprovalDetails} />
                <InnerLayout exact path={ROUTE.ALL_APPLICATIONS} component={AllApplications} />
                <InnerLayout exact path={ROUTE.EMICALCULATOR} component={EmiCalculator} />
                <InnerLayout exact path={ROUTE.DUE_EMIS} component={DueEmis} />
                <InnerLayout exact path={ROUTE.PAID_DETAIL_VIEW} component={PaidDetailView} />
                <InnerLayout exact path={ROUTE.RECIEVE_DETAIL_VIEW} component={RecievedDetailView} />
                <InnerLayout exact path={ROUTE.PROCESSING_FEE} component={ProcessingFee} />
                <InnerLayout exact path={ROUTE.PROFIT} component={Profit} />
                <InnerLayout exact path={ROUTE.ADD_MEMBER_GROUP} component={AddProcessingFee} />
                <InnerLayout exact path={ROUTE.OUTSTANDING_DETAIL_VIEW} component={OutstandingDetailView} />
                <InnerLayout exact path={ROUTE.EXPENSE} component={Expense} />
                <InnerLayout exact path={ROUTE.ADD_EXPENSE} component={AddExpense} />
                <InnerLayout exact path={ROUTE.LIST_USER} component={ListUser} />
                <InnerLayout exact path={ROUTE.ADD_USER} component={AddUser} />
                <InnerLayout exact path={ROUTE.VILLAGE} component={Village} />
                <InnerLayout exact path={ROUTE.ADD_VILLAGE} component={AddVillage} />
                <InnerLayout exact path={ROUTE.MEMBER_VILLAGE_MAP} component={MemberVillageMap} />
                <InnerLayout exact path={ROUTE.EMI_APPROVAL} component={EmiApproval} />
                <InnerLayout exact path={ROUTE.EDIT_SCHEME} component={EditScheme} />
                <InnerLayout exact path={ROUTE.EDIT_MEMBER} component={EditMember} />
                <InnerLayout exact path={ROUTE.EDIT_MEMBER_GROUP} component={EditMemberGroup} />
                <InnerLayout exact path={ROUTE.EDIT_EXPENSES} component={EditExpense} />
                <InnerLayout exact path={ROUTE.EDIT_LOAN} component={EditLoanApplication} />
                <InnerLayout exact path={ROUTE.EDIT_VILLAGE} component={EditVillage} />
                <InnerLayout exact path={ROUTE.EDIT_PROCESSING_FEE} component={EditProcessingFee} />
                <InnerLayout exact path={ROUTE.PRINT_LOAN} component={PrintLoan} />
                <InnerLayout exact path={ROUTE.OPEN_SAVING_ACCOUNT} component={OpenSavingAccount} />
                <InnerLayout exact path={ROUTE.SAVING_ACCOUNT_REPORT} component={SavingAccountReport} />
                <InnerLayout exact path={ROUTE.SVAC_DEPOSITED_REPORT} component={SavingAccountDepositedReport} />
                <InnerLayout exact path={ROUTE.ADD_TRANSACTION_SVAC} component={AddTransaction} />
                <InnerLayout exact path={ROUTE.INVESTOR_DASHBOARD} component={AdminInvestorDashboard} />
                <InnerLayout exact path={ROUTE.LOAN_CASH_AC_TRANSFER} component={LoanCashAcTransfer} />
                <InnerLayout exact path={ROUTE.EMI_COLLECTION_SHEET} component={collectionSheet} />
                <InnerLayout exact path={ROUTE.CLOSE_GROUP_LOAN_ACCOUNT} component={CloseAccount} />
                <InnerLayout exact path={ROUTE.MEMBER_APPROVAL} component={MemberApproval} />
                <InnerLayout exact path={ROUTE.ADMIN_MAIN_LEDGER_DETAIL} component={AdminMainLedgerDetail} />
                <InnerLayout exact path={ROUTE.PROFIT_LEDGER_DETAILS} component={ProfitLedgerDetails} />
                <InnerLayout exact path={ROUTE.MAIN_LEDGER_DETAIL_INVESTOR} component={MainLedgerDetailInvestor} />
                <InnerLayout exact path={ROUTE.LOAN_ACTIONS} component={LoanActions} />
                <InnerLayout exact path={ROUTE.OVERDUES} component={OverdueEmis} />
                <InnerLayout exact path={ROUTE.CASHBOOK} component={Cashbook} />
                <InnerLayout exact path={ROUTE.LOAN_DELETION_APPROVAL} component={LoanDeleteApproval} />

                <Route exact path={ROUTE.OVERDUE_NOTICE} component={OverdueNoticePrint} />
                <Route path={ROUTE.LOGIN} component={Login} />
                <Route path={ROUTE.DOWNLOAD_DOC} component={DownloadDoc} />
                <Route path={ROUTE.PRINT_JOINT_LIABILITY} component={PrintJointLiabilityForm} />
                <Route path={ROUTE.COMPANY_EXPENSE_SLIP_PDF} component={CompanyExpenseSlipPdf} />
                <Route path={ROUTE.PASSBOOK} component={PassbookPdf} />

                <InnerLayout path={ROUTE.REGISTRATION} component={Registration} />
                <InnerLayout path={ROUTE.MEMBER} component={Member} />
                <InnerLayout path={ROUTE.USER_MENUS} component={UserMenus} />
                <InnerLayout path={ROUTE.LOAN_LAST_EMI_DEPOSIT_DIFF} component={LastEmiDepositReport} />
                <InnerLayout path={ROUTE.LOAN_TOPUP} component={LoanTopup} />
                <InnerLayout path={ROUTE.DATABASE_BACKUP} component={DatabaseBackup} />
                <InnerLayout path={ROUTE.CLOSED_LOANS_LIST} component={AllClosedLoanApplications} />

            </Switch>
        </BrowserRouter>
    )
}

export default Routes