import { useEffect, useMemo, useState } from "react";
import { Button, ButtonGroup, Card, Col, Container, ListGroup, Row, Tab, Table } from "react-bootstrap";
import BorrowerCard from "./BorrowerCard";
import LoanDetailsCard from "./LoanDetailsCard";
import groupLoan from '../../models/groupLoan';
import moment from 'moment';
import CoborrowerCard from "./CoborrowerCard";
import EmiCard from "./EmiCard";
import AdvanceEmiDeposit from "./AdvanceEmiDeposit";
import Loader from "../layout/Loader";
import ROUTE from "../../constants/routes";
import { useHistory } from "react-router-dom";
import EmiHistory from "./EmiHistory";

function LoanActions(props) {
    const [loanDetails, setLoanDetails] = useState({});
    const [savings, setSavings] = useState([])
    const [paidEmiRecord, setPaidEmiRecord] = useState({})
    const [isShowLoader, setisShowLoader] = useState(false)
    const [emiData, setEmiData] = useState([])
    const [overdueEmis, setOverdueEmis] = useState({})
    let history = useHistory();

    useEffect(() => {
        getLoanDetails();
    }, [])

    useEffect(() => {
        console.log(window.location.hash);
        if (window.location.hash === "#overdueemichart") {
            getAllEmis(loanDetails.loan_account_no)
        }
    }, [window.location.hash])

    const getAllEmis = async (loan_account_no) => {
        try {
            setisShowLoader(true);
            let allEmis = [];
            let overdueEmis = [];
            let result = [];
            let totalOverdues = {
                EMI: 0,
                principal: 0,
                int_amount: 0,
                no_of_emi: 0,
                totalOverdue: 0
            }
            allEmis = await groupLoan.EmiModel.getEmisByLoanAccount(loan_account_no);
            overdueEmis = allEmis.body.message.filter(emi => emi.isPaid == 0 && moment(emi.EMI_date).isBefore(moment().format("YYYY-MM-DD")))
            result = overdueEmis.map(oemi => {
                oemi["EMI"] = oemi.EMI_amount;
                oemi["date"] = "";
                return oemi;
            })
            result.map(res => {
                totalOverdues.EMI += res.EMI;
                totalOverdues.totalOverdue += (res.principal - res.principal_received) + (res.int_amount - res.int_amount_received);
                totalOverdues.principal += (res.principal - res.principal_received);
                totalOverdues.int_amount += (res.int_amount - res.int_amount_received);
                totalOverdues.no_of_emi += 1
            })
            console.log(totalOverdues)
            setOverdueEmis(totalOverdues);
            setisShowLoader(false);
            if (allEmis.statusCode == 200) {
            }

        } catch (error) {
            setisShowLoader(false);
            console.log(error);
        }

    }
    const getLoanDetails = async () => {
        try {
            setisShowLoader(true);

            let response = await groupLoan.GroupLoanModel.getLoanDetailbyId(props.location.state);
            let calculateEmi = {
                "loanStartDate": response.body.message.rows[0].sanction_date ? response.body.message.rows[0].sanction_date : new Date(),
                "interest_rate": response.body.message.rows[0].interest_rate,
                "tenure": response.body.message.rows[0].tenure,
                "EMI_payout": response.body.message.rows[0].EMI_payout,
                "loan_amount": response.body.message.rows[0].loan_amount,
                "EMI_type": response.body.message.rows[0].EMI_type
            }
            let EmiData = await groupLoan.EmiModel.getEmiHistory(response.body.message.rows[0].loan_account_no);
            // let paidData = [];
            // let result = {};
            // let savingRecords = {};
            // paidData = await groupLoan.EmiModel.getPaidEmis(response.body.message.rows[0].loan_account_no ? response.body.message.rows[0].loan_account_no : null);
            // for (let i = 0; i < paidData.body.message.length; i++) {
            //     let emiDate = moment(paidData.body.message[i].EMI_date).format("DD-MM-yyyy");
            //     result[emiDate] = {
            //         EMI_date: emiDate,
            //         emi_paid_date: paidData.body.message[i].emi_paid_date,
            //         emi_approved_date: paidData.body.message[i].emi_approved_date,
            //     };
            //     savingRecords[emiDate] = paidData.body.message[i].saving_account_deposite?.amount;
            // }
            // setPaidEmiRecord(result);
            // setSavings(savingRecords);
            setEmiData(EmiData.body.message);

            setisShowLoader(false);
            if (response.statusCode == 200) {
                setLoanDetails(response.body.message.rows[0]);
            }

        } catch (error) {
            setisShowLoader(false);
            console.log(error);
        }
    }
    const overdueNotice = () => {
        window.open(`${ROUTE.OVERDUE_NOTICE}?loan_id=` + loanDetails.id, "_blank")
    }

    const loanDeleteRequest = async (id, loan_account_no) => {
        let action = window.confirm(`Do you really want to delete ${loan_account_no} ? This action will delete loan EMIs and savings also.`)
        if (action) {
            try {
                setisShowLoader(true);
                let response = await groupLoan.GroupLoanModel.loanDeleteRequest(id);
                setisShowLoader(false);
                if (response.statusCode == 200) {
                    history.push(ROUTE.ALL_APPLICATIONS)
                    alert(response.body.message)
                }
            } catch (error) {
                setisShowLoader(false);
                console.log(error);
            }

        }
    }
    const sanctionBack = async() =>{
        let action = window.confirm(`Do you really want to back loan to sanction?`)
        if (action) {
            try {
                setisShowLoader(true);
                let response = await groupLoan.GroupLoanModel.sanctionBackLoan({
                    loan_account_no: loanDetails.loan_account_no
                });
                setisShowLoader(false);
                if (response.statusCode == 200) {
                    history.push(ROUTE.LOANAPPROVAL)
                    alert(response.body.message)
                }
            } catch (error) {
                setisShowLoader(false);
                console.log(error);
            }

        }

    }
    const editLoanApplication = async () => {
        history.push('/loanApplication', loanDetails.id);
    }

    return (
        <Tab.Container id="list-group-tabs-example" defaultActiveKey="#applicationDetails">
            <Row>
                <Loader show={isShowLoader} />

                <Col md={3}>
                    <Card border="primary" header
                        key={0}
                        text={'dark'}
                    >
                        <Card.Header className="bg-info text-center"><b>Options</b>
                        </Card.Header>
                        <Card.Body>

                            <ListGroup defaultActiveKey="#applicationDetails">
                                <ListGroup.Item action href="#applicationDetails">
                                    Application Details
                                </ListGroup.Item>
                                <ListGroup.Item action href="#borrowerDetails">
                                    Borrower Details
                                </ListGroup.Item>
                                <ListGroup.Item action href="#coborrowerDetails">
                                    Coborrower Details
                                </ListGroup.Item>
                                <ListGroup.Item action href="#emiCard">
                                    EMI Card
                                </ListGroup.Item>
                                <ListGroup.Item action href="#Advanceemi">
                                    Advance EMI Deposit
                                </ListGroup.Item>
                                <ListGroup.Item action href="#overdueemichart">
                                    Overdue EMIs
                                </ListGroup.Item>

                            </ListGroup>
                        </Card.Body>
                    </Card>
                    <Card border="primary" header
                        key={0}
                        text={'dark'}
                    >
                        <Card.Header className="bg-info text-center"><b>Actions</b>
                        </Card.Header>
                        <Card.Body className="text-center">

                            <ButtonGroup vertical>
                            <Button 
                                variant="info" 
                                onClick={() => editLoanApplication()}>
                                    Edit Application
                                </Button>

                            <Button
                                    variant="success"
                                    disabled={loanDetails.sanction_date != moment().format("YYYY-MM-DD") }
                                    onClick={sanctionBack}
                                > Back to Sanction</Button>

                                <Button variant="danger" onClick={() => loanDeleteRequest(loanDetails.id, loanDetails.loan_account_no)}> Delete A/C</Button>
                            </ButtonGroup>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={9}>
                    <Tab.Content>
                        <Tab.Pane eventKey="#applicationDetails">
                            <LoanDetailsCard loanDetails={loanDetails} emiData={emiData} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="#borrowerDetails">
                            <BorrowerCard loanDetails={loanDetails} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="#coborrowerDetails">
                            <CoborrowerCard loanDetails={loanDetails} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="#emiCard">
                            <EmiHistory
                                emiData={emiData}
                                min_saving_amount={loanDetails.min_saving_amount}
                                showDisburseButton={loanDetails.is_sanctioned == 1 && loanDetails.is_disbursed == 1}

                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey="#Advanceemi">
                            <AdvanceEmiDeposit
                                loanDetails={loanDetails}
                                setisShowLoader={setisShowLoader}
                                isShowLoader={isShowLoader}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey="#overdueemichart">
                            {overdueEmis ?
                                <>
                                    <Button size="sm" variant="warning" onClick={overdueNotice}>Print Overdue Notice</Button>
                                    <Card border="primary" header
                                        key={0}
                                        text={'dark'}
                                    >
                                        <Card.Header className="bg-primary text-center"><b>Total Overdue</b></Card.Header>
                                        <Card.Body>
                                            {/* <Loader show={isShowEMILoader} relative={true}/> */}
                                            <Table size={"sm"} className="bg-white rounded small" bordered responsive>
                                                <thead>
                                                    <tr className="bg-success">
                                                        <th>No of pending Emis</th>
                                                        <th>Total Overdue Amount</th>
                                                        <th>Principal</th>
                                                        <th>Interest</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{overdueEmis.no_of_emi}</td>
                                                        <td>{overdueEmis.totalOverdue}</td>
                                                        <td>{overdueEmis.principal?.toFixed(2)}</td>
                                                        <td>{overdueEmis.int_amount?.toFixed(2)}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Card.Body>
                                    </Card>
                                    {/* <EmiCard
                                        showDisburseButton={false}
                                        emiData={overdueEmis}
                                        savings={savings}
                                        min_saving_amount={loanDetails.min_saving_amount}
                                        paidEmiRecord={[]}
                                        label="Overdues"
                                    /> */}
                                </>
                                : <p>No Overdues</p>}

                        </Tab.Pane>

                    </Tab.Content>
                </Col>
            </Row>
        </Tab.Container>
    )
}
export default LoanActions;