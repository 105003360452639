import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { Button, Card, Form, Toast, Container, Row, Col } from 'react-bootstrap';
import { useFormik } from 'formik';
import user from '../../models/user';
import Loader from '../layout/Loader';
import { useHistory } from 'react-router-dom';
import DatePicker from "react-datepicker";
import moment from 'moment';
import { useSelector } from 'react-redux';
import UserInvestors from './UserInvestors';
import village from '../../models/villageRecord';
import UserMenus from './UserMenus';
import { DEFAULT_INV_MENUS, DEFAULT_STAFF_MENUS } from '../../constants/constants';
import ButtonRnl from '../common/Button';
import BranchVillage from './BranchVillage';

function AddUser(props) {
  let history = useHistory();
  const [values, setValues] = useState({});
  const [isShowLoader, setisShowLoader] = useState(false)
  const [showToast, setShowToast] = useState({ isShow: false, type: "", message: "" })
  const [dob, setDob] = useState();
  const [joiningDate, setJoiningDate] = useState();
  const [selectedInvestors, setSelectedInvestors] = useState([]);
  const [selectedMenus, setSelectedMenus] = useState([]);
  const [menus, setMenus] = useState([]);
  const [allInvestors, setAllInvestors] = useState([]);
  const [allRoles, setAllRoles] = useState([]);
  const [allBranches, setAllBranches] = useState([]);
  const [allVillages, setAllVillages] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState([]);
  const [selectedVillage, setSelectedVillage] = useState([]);
  const [anniversaryDate, setAnniversaryDate] = useState();
  const [selectedMenusActions, setSelectedMenusActions] = useState([]);

  const auth = useSelector(state => state.auth);

  useLayoutEffect(() => {
    getRolesBranches();
    getAllMenus();
    // if (props.location.state) {
    //   getMappedMenu();
    //   getMappedMenuActions();
    //   getMappedBranch();
    //   getMappedVillage()
    //   getExpenseDetails(props.location.state);
    // }

  }, [])
  useEffect(()=>{
    if(allBranches.length && allVillages.length && props.location.state){
      getMappedMenu();
      getMappedMenuActions();
      getMappedBranch();
      getMappedVillage()
      getExpenseDetails(props.location.state);
    }
  },[allBranches, allVillages])
  const getExpenseDetails = async (id) => {
    try {
      setisShowLoader(true);
      let response = await user.Auth.getUserById(props.location.state);
      formik.values.name = response.body.message[0].name;
      formik.values.username = response.body.message[0].username;
      formik.values.password = response.body.message[0].password;
      formik.values.role = response.body.message[0].role;
      formik.values.phone = response.body.message[0].phone;
      formik.values.age = response.body.message[0].age;
      formik.values.dob = response.body.message[0].dob;
      formik.values.gender = response.body.message[0].gender;
      formik.values.address = response.body.message[0].address;
      formik.values.joining_date = response.body.message[0].joining_date;
      formik.values.bank_account_no = response.body.message[0].bank_account_no;
      formik.values.bank_ifsc_code = response.body.message[0].bank_ifsc_code;
      formik.values.bank_account_holder = response.body.message[0].bank_account_holder;
      formik.values.blood_group = response.body.message[0].blood_group;
      formik.values.investor_percentage = response.body.message[0].investor_percentage;
      formik.values.aadhar_card = response.body.message[0].aadhar_card;
      formik.values.pan_card = response.body.message[0].pan_card;
      setDob(new Date(response.body.message[0].dob))
      setJoiningDate(new Date(response.body.message[0].joining_date))
      setAnniversaryDate(new Date(response.body.message[0].anniversary_date))
      //formik.values.amount = response.body.message[0].amount
      setisShowLoader(false);
    } catch (error) {
      setisShowLoader(false);
      setShowToast({ isShow: true, type: "bg-danger", message: error.response.body.message })
    }
  }
  const getAllMenus = async () => {
    try {
      setisShowLoader(true);
      let response = await user.Auth.getAllMenus();
      setisShowLoader(false);
      if (response.statusCode == 200) {
        setMenus(response.body.message)
      }
    } catch (error) {
      setisShowLoader(false);
    }
  }
  const getMappedMenu = async () => {
    try {
      setisShowLoader(true);
      let response = await user.Auth.getUserMenu(props.location.state);
      setisShowLoader(false);
      if (response.statusCode == 200) {
        let existMenuids = response.body.message.map(val => val.menu_id)
        setSelectedMenus([...existMenuids])
      }
    } catch (error) {
      setisShowLoader(false);
    }
  }
  const getMappedMenuActions = async () => {
    try {
      setisShowLoader(true);
      let response = await user.Auth.getUserMenuAction(props.location.state);
      setisShowLoader(false);
      if (response.statusCode == 200) {
        setSelectedMenusActions([...response.body.message])
      }
    } catch (error) {
      setisShowLoader(false);
    }
  }
  const getParentId = (childId) => {
    let returnVal = "";
    menus.find(mn => {
      if (mn.submenu.length) {
        let menuDetails = mn.submenu.find(sub => sub.id == childId)
        if (menuDetails) {
          returnVal = menuDetails.parent_menu_id
        }
      }
    })
    return returnVal;
  }
  // const getAllInvestors = async () => {
  //   try {
  //     setisShowLoader(true);
  //     let response = await user.Auth.getUserByRole("investor");
  //     setisShowLoader(false);
  //     if (response.statusCode == 200) {
  //       setAllInvestors(response.body.message)
  //     }
  //   } catch (error) {
  //     setisShowLoader(false);
  //   }
  // }
  // const getMappedInvestor = async () => {
  //   try {
  //     setisShowLoader(true);
  //     let response = await user.Auth.getInvestorByStaff(props.location.state);
  //     setisShowLoader(false);
  //     if (response.statusCode == 200) {
  //       let selectedInvId = response.body.message.map(val => val.investor_id);
  //       setSelectedInvestors(selectedInvId);
  //     }
  //   } catch (error) {
  //     setisShowLoader(false);
  //   }
  // }
  const getMappedBranch = async () => {
    try {
      setisShowLoader(true);
      let response = await user.Auth.getBranchByUser(props.location.state);
      setisShowLoader(false);
      if (response.statusCode == 200) {
        let selectedBrId = response.body.message.map(val => val.branch_code);
        setSelectedBranch(selectedBrId);
      }
    } catch (error) {
      setisShowLoader(false);
    }
  }
  const getMappedVillage = async () => {
    try {
      setisShowLoader(true);
      let response = await user.Auth.getVillageByUser(props.location.state);
      setisShowLoader(false);
      if (response.statusCode == 200) {
        let selectedVillId = response.body.message.map(val => val.village_code);
        setSelectedVillage(selectedVillId);
      }
    } catch (error) {
      setisShowLoader(false);
    }
  }

  const validate = values => {
    const errors = {};
    if (!values.name) {
      errors.name = 'This field is Required!';
    }
    if (!values.username) {
      errors.username = 'This field is Required!';
    }
    if (!values.password) {
      errors.password = 'This field is Required!';
    }
    if (!values.role) {
      errors.role = 'This field is Required!';
    }
    if (!values.phone) {
      errors.phone = 'This field is Required!';
    }
    return errors;
  };
  const getRolesBranches = async () => {
    try {
      setisShowLoader(true);
      let records = await Promise.allSettled([user.Auth.getAllBranches(), user.Auth.getAllRoles(), village.VillageModel.getAllVillages()])
      setisShowLoader(false);
      if (records[0].value.status == 200) {
        setAllBranches(records[0].value.body.message)
      }
      if (records[1].value.status == 200) {
        setAllRoles(records[1].value.body.message)
      }
      if (records[2].value.status == 200) {
        setAllVillages(records[2].value.body.message)
      }
    } catch (error) {
      setisShowLoader(false);
    }
  }
  const formik = useFormik({
    initialValues: {
      name: '',
      username: '',
      password: '',
      role: "",
      dob: dob,
      age: null,
      gender: null,
      joining_date: joiningDate,
      address: null,
      bank_account_no: null,
      bank_ifsc_code: null,
      bank_account_holder: null,
      blood_group: null,
      phone: "",
      investor_percentage: 0,
      userMenus: [],
      user_branch: [],
      user_village: [],
      anniversary_date : anniversaryDate,
      aadhar_card:"",
      pan_card:""
    },
    validate,
    onSubmit: async (values) => {
      setisShowLoader(true);
      try {
        values.joining_date = moment(joiningDate).format("YYYY-MM-DD");
        values.anniversary_date = moment(anniversaryDate).format("YYYY-MM-DD");
        values.dob = moment(dob).format("YYYY-MM-DD");
        values.user_branch = selectedBranch;
        values.user_village = selectedVillage;
        let selMenu = [];
        selectedMenus.forEach(val => {
          let parentId = getParentId(val);
          if (parentId) {
            let isParentmapped = selMenu.indexOf(parentId) // check if parent already mapped
            if (isParentmapped == -1) {
              selMenu.push(parentId)
            }
          }
          selMenu.push(val)
        })
        values.userMenus = [...new Set(selMenu)]
        values.selectedMenusActions = selectedMenusActions
        let response = [];
        if (props.location.state) {
          values["id"] = props.location.state;
          response = await user.Auth.editUser(values);
          history.push("/listUser")
        } else {

          response = await user.Auth.createUser(values);
        }
        setisShowLoader(false);
        if (response.statusCode == 200) {
          formik.resetForm();
          setSelectedInvestors([]);
          setSelectedMenus([]);
          if (props.location.state)
            history.push("/listUser")
          setShowToast({ isShow: true, type: "bg-success", message: "Data added Successfully!" })
        } else {
          setShowToast({ isShow: true, type: "bg-danger", message: response.body.message.toString() })
        }


      } catch (error) {
        setisShowLoader(false);
        setShowToast({ isShow: true, type: "bg-danger", message: error.response.body.message.toString() })
      }
    },
    handleChange: () => {
      setValues(prevValues => ({
        ...prevValues,
        // we use the name to tell Formik which key of `values` to update
        [values.target.name]: values.target.value
      }))
    },
  });
  const changeDob = (db) => {
    setDob(db)
    var a = moment();
    var b = moment(db);
    formik.values.age = a.diff(b, 'year')
  }
  const changeRole = (e) => {
    if (e.target.value == "corporate_investor" || e.target.value == "retail_investor") {
      setSelectedMenus(DEFAULT_INV_MENUS)
    } else {
      setSelectedMenus(DEFAULT_STAFF_MENUS)
    }
    formik.handleChange(e);
  }
  return (
    <>
      <Container fluid>
        <Toast key={1} autohide delay={3000} show={showToast.isShow} onClose={() => setShowToast({ isShow: false, type: "", message: "" })} className={"loader " + (showToast.type)} >
          <Toast.Header>
            <strong className="me-auto">{showToast.type == "bg-danger" ? "Error" : "Success"} Message</strong>
          </Toast.Header>
          <Toast.Body className="Dark">
            {showToast.message}
          </Toast.Body>
        </Toast>
        <Loader show={isShowLoader} />
        <Row>
          <Col style={{ paddingRight: 2, paddingLeft: 2 }}>
            <Card border="info"
              text={'dark'}
              className="mb-2"
            >
              <Card.Header className="bg-info text-center"><b>Basic Details</b></Card.Header>
              <Card.Body>

                <Form onSubmit={formik.handleSubmit}>
                  {/* <Row className="mb-3" md={6}> */}
                  <Form.Group controlId="formGridname" className="form-group required">
                    <Form.Label>Name:</Form.Label>
                    <Form.Control
                      name="name"
                      onChange={formik.handleChange}
                      value={formik.values.name}
                      autoComplete="off"
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <div className="text-danger">{formik.errors.name}</div>
                    ) : null}
                  </Form.Group>
                  {/* </Row> */}
                  {/* <Row className="mb-3" md={6}> */}


                  <Form.Group controlId="formGridrole" className="form-group required">
                    <Form.Label>Role</Form.Label>
                    <select
                      className="form-control"
                      name="role"
                      onChange={changeRole}
                      value={formik.values.role}
                    >
                      <option key="" value="">Select Role</option>
                      {allRoles.map(val => <option key={val.role_code} value={val.role_code}>{val.role_name}</option>)}
                    </select>
                    {formik.touched.role && formik.errors.role ? (
                      <div className="text-danger">{formik.errors.role}</div>
                    ) : null}

                  </Form.Group>
                  <Form.Group className="form-group" controlId="formGriddob">
                    <Form.Label>Date of Birth</Form.Label>
                    <DatePicker
                      className="form-control"
                      selected={dob}
                      onChange={(date) => changeDob(date)}
                      name="dob"
                      dateFormat="dd/MM/yyyy"
                      maxDate={new Date()}
                      showYearDropdown
                    />

                  </Form.Group>
                  <Form.Group controlId="formGridage" className="form-group required">
                    <Form.Label>Age</Form.Label>
                    <Form.Control
                      name="age"
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.age}
                    />
                  </Form.Group>
                  <Form.Group controlId="formGridgender" className="form-group required">
                    <Form.Label>Gender</Form.Label>
                    <select
                      className="form-control"
                      name="gender"
                      onChange={formik.handleChange}
                      value={formik.values.gender}
                    >
                      <option key="" value="">Select Gender</option>
                      <option key="male" value="male">Male</option>
                      <option key="female" value="female">Female</option>
                    </select>
                  </Form.Group>
                  {formik.values.role != "corporate_investor" && formik.values.role != "retail_investor" && <Form.Group className="form-group" controlId="formGridjoining_date">
                    <Form.Label>Joining Date</Form.Label>
                    <DatePicker
                      className="form-control"
                      selected={joiningDate}
                      onChange={(date) => setJoiningDate(date)}
                      name="joining_date"
                      dateFormat="dd/MM/yyyy"
                    />
                  </Form.Group>}
                  <Form.Group controlId="formGridaddress" className="form-group required">
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      name="address"
                      type="textarea"
                      onChange={formik.handleChange}
                      value={formik.values.address}
                    />
                  </Form.Group>

                  <Form.Group controlId="formGridphone" className="form-group required">
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                      name="phone"
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.phone}
                    />
                    {formik.touched.phone && formik.errors.phone ? (
                      <div className="text-danger">{formik.errors.phone}</div>
                    ) : null}
                  </Form.Group>
                  <Form.Group controlId="formGridblood_group" className="form-group required">
                    <Form.Label>Blood Group</Form.Label>
                    <Form.Control
                      name="blood_group"
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.blood_group}
                    />
                  </Form.Group>
                  <Form.Group controlId="formGridaadhar_card" className="form-group required">
                    <Form.Label>Aadhar Card Number</Form.Label>
                    <Form.Control
                      name="aadhar_card"
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.aadhar_card}
                    />
                  </Form.Group>
                  <Form.Group controlId="formGridpan_card" className="form-group required">
                    <Form.Label>Pan Card Number</Form.Label>
                    <Form.Control
                      name="pan_card"
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.pan_card}
                    />
                  </Form.Group>
                  <Form.Group className="form-group" controlId="formGridanniversary_date">
                  <Form.Label>Marriage Anniversary Date</Form.Label>
                    <DatePicker
                      className="form-control"
                      selected={anniversaryDate}
                      onChange={(date) => setAnniversaryDate(date)}
                      name="anniversary_date"
                      dateFormat="dd/MM/yyyy"
                    />
                  </Form.Group>
                  {(formik.values.role == "corporate_investor" || formik.values.role == "retail_investor") && <Form.Group controlId="formGridinvestor_percentage" className="form-group required">
                    <Form.Label>Investor Percentage</Form.Label>
                    <Form.Control
                      name="investor_percentage"
                      type="number"
                      onChange={formik.handleChange}
                      value={formik.values.investor_percentage}
                    />
                  </Form.Group>}

                  {/* </Row> */}
                  <ButtonRnl variant="primary" type="submit">
                    Save
                  </ButtonRnl>

                </Form>
              </Card.Body>
            </Card>
          </Col>
          <Col style={{ paddingRight: 2, paddingLeft: 2 }}>
            <Card border="info"
              text={'dark'}
              className="mb-2"
            >
              <Card.Header className="bg-info text-center"><b>Credentials</b></Card.Header>
              <Card.Body>
                <Form.Group controlId="formGridusername" className="form-group required">
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    name="username"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.username}
                    autoComplete="off"
                  />
                  {formik.touched.username && formik.errors.username ? (
                    <div className="text-danger">{formik.errors.username}</div>
                  ) : null}

                </Form.Group>
                <Form.Group controlId="formGridpassword" className="form-group required">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    name="password"
                    type="password"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    autoComplete="off"
                  />
                  {formik.touched.password && formik.errors.password ? (
                    <div className="text-danger">{formik.errors.password}</div>
                  ) : null}

                </Form.Group>
              </Card.Body>
            </Card>
            <Card border="info"
              text={'dark'}
              className="mb-2"
            >
              <Card.Header className="bg-info text-center"><b>Bank Account Details</b></Card.Header>
              <Card.Body>
                <Form.Group controlId="formGridbank_account_no" className="form-group required">
                  <Form.Label>Account Number</Form.Label>
                  <Form.Control
                    name="bank_account_no"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.bank_account_no}
                    autoComplete="off"
                  />
                </Form.Group>
                <Form.Group controlId="formGridbank_ifsc_code" className="form-group required">
                  <Form.Label>IFSC Code</Form.Label>
                  <Form.Control
                    name="bank_ifsc_code"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.bank_ifsc_code}
                    autoComplete="off"
                  />
                </Form.Group>
                <Form.Group controlId="formGridbank_account_holder" className="form-group required">
                  <Form.Label>Account Holder</Form.Label>
                  <Form.Control
                    name="bank_account_holder"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.bank_account_holder}
                    autoComplete="off"
                  />
                </Form.Group>

              </Card.Body>
            </Card>
            {formik.values.role != "" && formik.values.role != "corporate_investor" && formik.values.role != "retail_investor" &&
              // <UserInvestors
              //   allInvestors={allInvestors}
              //   selectedInvestors={selectedInvestors}
              //   setSelectedInvestors={setSelectedInvestors} />
              <BranchVillage
                allBranches = {allBranches}
                selectedBranch = {selectedBranch}
                setSelectedBranch = {setSelectedBranch}
                allVillages = {allVillages}
                setSelectedVillage = {setSelectedVillage}
                selectedVillage = {selectedVillage}
                role = {formik.values.role}
              />

            }

          </Col>
          <Col style={{ paddingRight: 2, paddingLeft: 2 }}>
            <Card border="info"
              text={'dark'}
              className="mb-2"
            >
              <Card.Header className="bg-info text-center"><b>Access Menus</b></Card.Header>
              <Card.Body>
                <UserMenus
                  setMenus = {setMenus}
                  menus = {menus}
                  setSelectedMenus = {setSelectedMenus}
                  selectedMenus = {selectedMenus}
                  setSelectedMenusActions = {setSelectedMenusActions}
                  selectedMenusActions = {selectedMenusActions}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AddUser;